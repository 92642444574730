import { types } from "mobx-state-tree"
import WorkingTrialBalanceViewModel from "./working-trial-balance.view-model"
import WorkingTrialBalanceModel from "./working-trial-balance.model"

export const WorkingTrialBalanceStore = () =>
	types
		.compose(WorkingTrialBalanceModel(), WorkingTrialBalanceViewModel)
		.named("WorkingTrialBalanceStore")

export const initialStore = {
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const WorkingTrialBalanceStoreInstance =
	WorkingTrialBalanceStore().create(initialStore)

export default WorkingTrialBalanceStoreInstance
