import { types } from "mobx-state-tree"

export const EntityPresentationModel = types.model({
	id: types.string,
	parentId: types.union(types.string, types.null),
	isParent: types.boolean,
	expanded: types.boolean,
	title: types.string,
	abbreviation: types.string,
})
