import { types, applySnapshot } from "mobx-state-tree"
import { OrgTrashItem, TrashType } from "./data-models/org-trash.data-models"
// ---------- common models
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"
// ---------- response cases
//----------- view model actions
import RemoveOrgTrashItem from "./view-model-actions/remove-org-trash"
import RestoreOrgTrashItem from "./view-model-actions/restore-org-trash"
import GetOrgTrashItem from "./view-model-actions/get-org-trash"
import { initialStore } from "./org-trash.provider"
import {
	formattedDateWithoutTzLabel,
	formattedDateWithTz2,
	getTzDate,
} from "../../../../library/converters/date-utc-converter"
import GetOrgNotiList from "../../../../service-modules/post/store-view-model-actions/get-org-noti-list"
import PermanentRemovePost from "../../../../service-modules/post/store-view-model-actions/permanent-remove-post"
import RemoveOrRestorePost from "../../../../service-modules/post/store-view-model-actions/remove-or-restore-post"
import { MST_Post } from "../../../lobby-side/right-drawer/store/data-models/notifications.data-model"
import { DnTFormatProps } from "../../../../common-models/types/common-props"

const OrgTrashViewModel = types
	.model({
		orgTrashList: types.array(OrgTrashItem),
		notiTrashList: types.array(MST_Post),
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setTrashList(trashList: any) {
			self.orgTrashList.length = 0
			self.orgTrashList = trashList
		},
		pushItemToList(item: any) {
			self.orgTrashList.push(item)
		},
		resetList() {
			self.orgTrashList.length = 0
		},
		deleteItem(itemId: string, trashType?: TrashType) {
			if (trashType === TrashType.noti) {
				self.notiTrashList.splice(
					self.notiTrashList.findIndex(
						(item: any) => item.id === itemId
					),
					1
				)
			} else {
				self.orgTrashList.splice(
					self.orgTrashList.findIndex(
						(item: any) => item.id === itemId
					),
					1
				)
			}
		},
		setOrgNotiList(list: any) {
			self.notiTrashList = list
		},
	}))
	.actions(GetOrgTrashItem)
	.actions(RemoveOrgTrashItem)
	.actions(RestoreOrgTrashItem)
	.actions(GetOrgNotiList)
	.actions(PermanentRemovePost)
	.actions(RemoveOrRestorePost)
	.views((self) => ({
		formattedOrgTrashList(dntFormat: DnTFormatProps) {
			let formattedList: any[] = []

			function formatDateWithTime(date: string | null) {
				if (date) {
					return formattedDateWithoutTzLabel({
						date,
						dntFormat,
					})
				}
				return ""
			}

			self.orgTrashList.map((item: any) => {
				// const formattedDeletedAt = item.deletedAt
				// 	? getTzDate({
				// 			date: item.deletedAt,
				// 			timeZone,
				// 			dateFormat,
				// 	  })
				// 	: ""
				formattedList.push({
					...item,
					deletedAt: formatDateWithTime(item.deletedAt),
				})
			})

			return formattedList
		},
		getTrashInfoById(trashId: string, trashType: TrashType) {
			if (trashType === TrashType.org) {
				return self.orgTrashList.find(
					(element: any) => element.id === trashId
				)
			} else {
				return self.notiTrashList.find(
					(element: any) => element.id === trashId
				)
			}
		},
		viewTrashItemType() {
			let trashType: string[] = []
			self.orgTrashList.map((trash) => {
				if (!trashType.some((item: string) => item === trash.type)) {
					trashType.push(trash.type)
				}
			})
			return trashType
		},
		getTrashItemTypeById(trashId: string) {
			return self.orgTrashList.find((trash) => trash.id === trashId)?.type
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default OrgTrashViewModel
