import React from "react"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "@datalobby/types"
import WorkingTrialBalance from "./WorkingTrialBalance"

export default observer(function WorkingTrialBalanceController() {
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.working_trial_balance
	const isLocked = projStore.projInfo.projInfo.isLocked
	const isArchived = projStore.projInfo.projInfo.isArchived
	const menuAccess = projStore.checkin.checkAccess(menuId)
	const permission = projStore.checkin.getActionsAsObject(
		menuId,
		isLocked,
		isArchived
	)

	return (
		<>
			{menuAccess ? (
				<WorkingTrialBalance
					partialStore={projStore.workingTrialBalance}
					permission={permission}
					actionStatus={ActionStatus.standby}
				/>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
