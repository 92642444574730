import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import { initialStore } from "./entity-presentation.provider"

import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"
import { EntityPresentationModel } from "./data-models/entity-presentation.data-model"
import GetEntityPresentation from "./view-model-actions/get-entity-presentation"
import { getTreeFromFlatData } from "../../../../../components/basic-elements/tree-list/getTreeFromFlatData"

const EntityPresentationViewModel = types
	.model({
		flatList: types.array(EntityPresentationModel),
		addLevel1EntityDialogOpen: false,
		selectedItem: "",
		clickPoint: ClickPoint,
		folderActionType: "add",
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar, // snackbar type 2
	})
	.actions((self) => ({
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setFlatList(list: any) {
			self.flatList = list
		},
		setSelectedItem(id: string) {
			self.selectedItem = id
		},
		setFolderActionType(type: string) {
			self.folderActionType = type
		},
		setAddLevel1EntityDialogOpen(request?: boolean) {
			self.addLevel1EntityDialogOpen =
				request ?? !self.addLevel1EntityDialogOpen
		},
		toggleExpanded(itemId: string, expanded?: boolean) {
			const target = self.flatList.find((item: any) => item.id === itemId)
			if (target) {
				target.expanded = expanded ?? !target.expanded
			}
		},
		pushItemToFlatList(item: any) {
			self.flatList.push(item)
		},
		updateFlatList(id: string, name: string) {
			const index = self.flatList.findIndex((item: any) => item.id === id)
			self.flatList[index].title = name
		},
	}))
	.actions(GetEntityPresentation)
	.views((self) => ({
		viewEntityPresentation() {
			return self.flatList
		},
		getFSGroupDetailsById(id: string) {
			return self.flatList.find((i: any) => i.id === id)
		},
		flatListAsTree() {
			const tree = getTreeFromFlatData(self.flatList, "title")

			return tree
		},
		isDuplicatedFolderName(folderName: string) {
			if (
				self.flatList
					.filter((item: any) => item.isParent)
					.findIndex(
						(item: any) =>
							item.title.toLowerCase() ===
							folderName.toLowerCase()
					) === -1
			) {
				return false
			} else {
				return true
			}
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default EntityPresentationViewModel
