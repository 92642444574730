// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import FileSaver from "file-saver"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - loading, success, fail
 *
 */

const DownloadFileFolder = (self: any) => ({
	downloadFileFolder({
		folderId,
		projId,
	}: {
		folderId: string
		projId?: string
	}) {
		// 0.
		const actionName = "downloadFileFolder"
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 999,
			color: MessageColorType.green,
			open: true,
			// autoHide: true,
		})
		// 3.
		console.log(folderId)
		console.log("folder")
		self.requestDonwloadFileFolder({
			projId,
			folderId,
		})
			.then((response: any) => {
				if (response.status === 200) {
					// console.log(actionName+ "__response " + response)
					const contentType = response.headers["content-type"]
					if (contentType?.includes("application/zip")) {
						const fileName = self.getItemInfo(folderId).title
						const fetchedFile = new Blob([response.data], {
							type: contentType,
						})
						FileSaver.saveAs(fetchedFile, fileName + ".zip")

						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200,
							color: MessageColorType.blue,
							open: true,
							// autoHide: true,
						})
					} else {
						// ✅ Case: Error Response (JSON inside ArrayBuffer)
						console.log(
							"Non-ZIP response, attempting to parse JSON error"
						)

						// Convert ArrayBuffer to String
						const textDecoder = new TextDecoder("utf-8")
						const jsonText = textDecoder.decode(
							new Uint8Array(response.data)
						)
						const errorJson = JSON.parse(jsonText)
						console.log("Error JSON:", errorJson)
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999,
							color: MessageColorType.orange,
							open: true,
							autoHide: false,
							message:
								errorJson.Message || "Unknown error occurred",
						})
					}
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default DownloadFileFolder
