import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import { initialStore } from "./working-trial-balance.provider"

import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"

const WorkingTrialBalanceViewModel = types
	.model({
		clickPoint: ClickPoint,
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar, // snackbar type 2
	})
	.actions((self) => ({
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default WorkingTrialBalanceViewModel
