import { types } from "mobx-state-tree"
import EntityPresentationViewModel from "./entity-presentation.view-model"
import EntityPresentationModel from "./entity-presentation.model"

export const EntityPresentationStore = () =>
	types
		.compose(EntityPresentationModel(), EntityPresentationViewModel)
		.named("EntityPresentationStore")

export const initialStore = {
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const EntityPresentationStoreInstance =
	EntityPresentationStore().create(initialStore)

export default EntityPresentationStoreInstance
